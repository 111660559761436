//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from "vue";
import MixinTeamOptions from "@/mixins/teamOptions";
import MixinWebrtc from "@/mixins/webrtcNew";
import Request from "@/webrtc/server/internal/Request";
export default {
  mixins: [MixinTeamOptions, MixinWebrtc],
  components: {
    Header: () => import("@/components/Header"),
    selMember: () => import("@/components/Selectors/MemberSelectCopy"),
  },
  data() {
    return {
      functionName: "打印监控",
      NeedDataChannel: true,
      ds: null, //开始时间
      de: null, //结束时间
      tableData: [],
      pageData: {
        pageIndex: 1,
        pageSize: 20,
        totalNum: 0,
      }, //分页
      loading: false,
      teamOptions: [], //团队选择器
      teamValue: null, //选择的团队
      selMem: [], //选择的成员
      dateType: 1, //时间查询类型
      selDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() + 1
      ),
      pickOption: this.pickDate(),
      DateRange: [
        new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate() - 7
        ),
        new Date(),
      ],
    };
  },
  methods: {
    async sendWebrtcRequestNoIdentity(request) {
      if (this.isLocalStorage) {
        var identity = this.selMem[0].Identity;
        await this.Call(identity);
        var b = this.SendRequest(null, identity, request);
        if (!b) {
          throw "发送请求失败";
        }
      }
    },
    /**
     * 获取列表数据
     */
    async handleGetData() {
      if (!this.teamValue) {
        this.$message({
          message: "请先选择团队",
          type: "warning",
        });
        return;
      }
      if (this.isLocalStorage && this.selMem.length == 0) {
        this.$message({
          message: "请先选择成员",
          type: "warning",
        });
        return;
      }
      this.pageData.pageIndex = 1;
      this.tableData = [];
      this.loading = true;
      this.HangUpAll();
      if (this.isLocalStorage) {
        var identity = this.selMem[0].Identity;
        try {
          await this.Call(identity);
        } catch (error) {}
      }
      this.queryData();
    },
    /**
     * 获取报表
     */
    queryData() {
      this.loading = true;
      const day = new Date(this.selDate).getDay();
      const dayNum = day > 0 ? day - 1 : 6;
      const date = new Date(this.selDate);
      if (this.dateType == 1) {
        this.ds = new Date(date.setDate(date.getDate() - dayNum)).timeFormat(
          "yyyy-MM-dd 00:00:01"
        );
        const s = new Date(this.ds);
        this.de = new Date(s.setDate(s.getDate() + 6)).timeFormat(
          "yyyy-MM-dd 23:59:59"
        );
      } else if (this.dateType == 2) {
        this.ds = new Date(date.getFullYear(), date.getMonth(), 1).timeFormat(
          "yyyy-MM-dd 00:00:01"
        );
        this.de = new Date(
          date.getFullYear(),
          date.getMonth() + 1,
          0
        ).timeFormat("yyyy-MM-dd 23:59:59");
      } else {
        this.ds = this.DateRange[0].timeFormat("yyyy-MM-dd 00:00:01");
        this.de = this.DateRange[1].timeFormat("yyyy-MM-dd 23:59:59");
      }
      const data = {
        startTime: this.ds,
        endTime: this.de,
        usIds: this.selMem.map((m) => m.UsId),
        pageIndex: this.pageData.pageIndex,
        pageSize: this.pageData.pageSize,
        teamId: this.teamValue,
        datetype: this.dateType,
      };
      if (this.isLocalStorage) {
        var self = this;
        var request = new Request();
        request.Url = "/Progress/Monitoring/GetPrintMonitoring";
        request.ContentType = "Json";
        const token = this.$xStorage.getItem("token");
        request.Headers.Authorization = token;
        request.Body = JSON.stringify(data);
        request.OnError = function (error) {
          self.$message({
            type: "error",
            message: error,
          });
          self.loading = false;
        };
        request.OnResponse = function (response) {
          let result = response.Result;
          if (!result.State) {
            self.$message({
              type: "error",
              message: result.Message,
            });
          } else {
            self.tableData = result.Data.Data;
            self.pageData.totalNum = result.Data.TotalCount;
          }
          self.loading = false;
        };
        this.sendWebrtcRequestNoIdentity(request).catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
          this.loading = false;
        });
      } else {
        this.$http
          .post("/Progress/Monitoring/GetPrintMonitoring.ashx", data)
          .then((resp) => {
            if (resp.res == 0) {
              this.tableData = resp.data.Data;
              this.pageData.totalNum = resp.data.TotalCount;
            }
          })
          .finally(() => (this.loading = false));
      }
    },
    async handleSizeChange(val) {
      this.pageData.pageSize = val;
      if (!this.teamValue) {
        this.$message({
          message: "请先选择团队",
          type: "warning",
        });
        return;
      }
      if (this.isLocalStorage && this.selMem.length == 0) {
        this.$message({
          message: "请先选择成员",
          type: "warning",
        });
        return;
      }
      if (this.isLocalStorage) {
        var identity = this.selMem[0].Identity;
        try {
          await this.Call(identity);
        } catch (error) {
          console.log(error);
        }
      }
      this.queryData();
    },
    async handleCurrentChange(val) {
      this.pageData.pageIndex = val;
      if (!this.teamValue) {
        this.$message({
          message: "请先选择团队",
          type: "warning",
        });
        return;
      }
      if (this.isLocalStorage && this.selMem.length == 0) {
        this.$message({
          message: "请先选择成员",
          type: "warning",
        });
        return;
      }
      if (this.isLocalStorage) {
        var identity = this.selMem[0].Identity;
        try {
          await this.Call(identity);
        } catch (error) {
          console.log(error);
        }
      }
      this.queryData();
    },
    pickDate() {
      let _this = this;
      return {
        disabledDate(time) {
          return time.getTime() > new Date().getTime();
        },
      };
    },
    /**
     * 选择成员
     */
    getSelMember(arr) {
      this.selMem = arr;
    },
    /**
     * 团队切换
     */
    handleTeamChange() {
      this.selMem = [];
    },
    attenceHeaderStyle() {
      return {
        color: "#909399",
      };
    },
  },
  beforeDestroy() {
    this.HangUpAll();
  },
};
